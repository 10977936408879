.App {
  font-family: 'Hack', monospace;
  text-align: center;
  overflow: hidden;
}
.App-h1{
  font-size: 100px;
}

.App-h1 img {
  max-width: 350px;
  max-height: 350px;
  border-radius: 100%;
  border: 5px solid #151515; /* Adjust the width as needed */
}

.left {
  float: left;
  padding: 10px;
  
}

.right {
  float: left;
}

.body1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
  min-width: 800px;
}

.right span{
  color: #fff;
  height: 100vh;
  display: grid;
  place-items: center;
}

.comment {
  color: #86C232;
}

.links {
  padding-top: 25px;
  align-items: center;
}

.link {
  text-decoration: none;
}

.link:hover {
  cursor: pointer;
  filter: brightness(150%);
}

.App-header {
  background: radial-gradient(ellipse at bottom, #222629 0%, #222 100%);
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  overflow-x: auto;
}

.typing {
  width: 13ch;
  animation: typing 1.5s steps(13) forwards, blink .5s step-end infinite alternate;
  animation-delay: 0s, 1.5s;
  white-space: nowrap;
  overflow: hidden;
  border-right: solid;
  font-family: monospace;
  font-size: 100px;
  color: #fff;
}
.typing2 {
  animation: typing2 1.5s steps(13) forwards;
  white-space: nowrap;
  overflow: hidden;
  font-family: monospace;
  font-size: 100px;
  color: transparent;
}

.footer {
  color: #fff;
  padding: 10px;
  margin-top: -45px;
  height: 45px;
  bottom: 0;
  width: 100%;
  text-align: center;
  position: relative;
}

.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.footer a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
}

.footer h{
  font-size: 16px;
}

.footer a:hover {
  filter: brightness(75%);
}

.background {
  background-image: url('../public/IMG_1657.JPG');
  background-size: cover;
  background-position: center;
  opacity: 0.3; /* Adjust the opacity level */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Make sure the background stays behind the text */
}

@keyframes typing {
  from {
    width: 0
  }
}
@keyframes typing2 {
  from {
    width: 13ch
  }
  to {
    width: 0
  }  
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

@media screen and (max-width: 1250px) {
  .left {
    float: none;
  }
  .comment {
    font-size: 12px;
  }
  .links{
    padding-top: 15px;
    font-size: 15px;
  }
  .typing {
    font-size: 40px;
  }
  .typing2 {
    font-size: 40px;
  }
  .body1 {
    margin-top: 0px;
  }
  .App-h1 img {
    max-width: 200px;
    max-height: 200px;
  }
  .footer a{
    font-size: 8px;
  }
  .footer h{
    font-size: 8px;
  }
  .footer-container{
    gap: 10px;
  }
}

.link:nth-child(1) { color: #845993; }
.link:nth-child(3) { color: #d58747; }
.link:nth-child(5) { color: #d54747; }